/* 渲染feed流 */

import React, { useState, useEffect, useRef } from 'react'
import ScrollLoader from 'Components/scroll-loader'
import get from 'lodash/get'

import { sendTeaCommEvent } from 'lib/tea-analyze'
import fetchFeed, { IFeedData } from 'lib/fetch-feed'

import Loading from 'Components/loading'
import Article from 'Components/article'
import FeedAd from 'Components/feed-ad'
import { IFeedAdData } from './hooks'

interface IProps {
  category: string
  subCategory?: string
  /**
   * 来自哪个页面，目前取值
   * * home 首页
   * * article 文章页
   * * series_home 车系详情
   */
  page: 'home' | 'article' | 'series_home' | 'feed'
  concern_id?: number
  onClick?: (
    group_id: string | number,
    content_type: number,
    index?: number
  ) => void
  feedAd?: IFeedAdData[]
  gid?: string
  contentType?: string
  from?: string
}

function Feed({
  category,
  subCategory = '',
  concern_id,
  onClick,
  feedAd,
  gid,
  page,
  contentType,
}: IProps) {
  const [list, updateList] = useState<IFeedData[]>([])
  // 是否在加载中
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    updateList([])
    fetchFeed({
      tt_from: 'enter_auto',
      concern_id,
      category,
      sub_category: subCategory,
    }).then(data => {
      setLoading(false)
      updateList(get(data, 'data'))
    })
  }, [category, concern_id, subCategory])

  /* 懒加载 */
  function loadMoreHandler() {
    let lastFeedItem: IFeedData | null = null
    if (list.length > 0) {
      lastFeedItem = list[list.length - 1]
    }
    setLoading(true)
    fetchFeed({
      tt_from: 'load_more',
      sub_category: subCategory,
      category,
      count: 20,
      concern_id,
      max_behot_time: get(lastFeedItem, 'info.behot_time', undefined),
    }).then(data => {
      setLoading(false)
      sendTeaCommEvent('loadmore_home_news')
      updateList(list.concat(get(data, 'data')))
    })
  }

  let renderIdx = 0

  return (
    <ScrollLoader
      loading={loading}
      loadMoreHandler={loadMoreHandler}
      end={false}
    >
      {list && list.length
        ? list.map((item: IFeedData, index) => {
          // 过滤非图文视频(不准确) // TODO: 确认这里过滤掉的类型具体有哪些
          // https://ee.byted.org/madeira/browse/service_rpc/idl/blob/master/motor/cell.thrift/
          if (item.type < 2000 || item.type >= 2300) {
            return null
          }
          renderIdx++
          if (feedAd && feedAd.length) {
            const findAd = feedAd.find(adItem => {
              return adItem.fix_position === renderIdx
            })
            if (findAd) {
              return (
                <FeedAd
                  page={page}
                  category={category}
                  gid={gid}
                  info={findAd}
                  index={renderIdx}
                  contentType={contentType}
                  key={findAd.id_str}
                />
              )
            }
          }
          const info = get(item, 'info', {}) as IFeedData['info']
          return (
            <Article
              onClick={onClick}
              fromPage={page}
              type={item.type}
              key={item.unique_id_str}
              gid={item.unique_id_str}
              cover={get(item, 'info.image_list[0].url', '')}
              duration={info.video_duration}
              title={info.title}
              source={info.source}
              time={info.cursor}
              content={info.abstract_content}
              index={index}
            />
          )
        })
        : null}
      {loading ? <Loading /> : null}
    </ScrollLoader>
  )
}
export default Feed
