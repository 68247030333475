/* feed流/相关推荐的文章 */
import React, { useEffect, useMemo } from 'react'
import Link from 'next/link'
import get from 'lodash/get'

import formatTime from 'lib/format-time'
import getLinkHref from 'lib/get-link-href'
import { useAdStat } from 'Components/ad/'

import Image from 'Components/image'

import { IFeedAdData } from 'Components/hooks'

interface IProps {
  category: string
  page: string
  info: IFeedAdData
  index: number
  gid: string | undefined
  contentType?: string
}

export default function FeedAd({ page, info, index }: IProps) {
  const { title, source, time, label } = info

  const cover = get(info, 'image_list[0].url', '')
  const href = info.web_url
  const as: string = getLinkHref(info.web_url)
  const customStat = useMemo(
    () => ({
      rank: String(index - 1),
    }),
    [index]
  )

  // 区分资讯页固定位广告和首页固定位广告的埋点信息
  const eventIds =
    page === 'feed'
      ? {
        send: 'ad_send_article_feed_cell',
        show: 'ad_show_article_feed_cell',
        click: 'ad_click_article_feed_cell',
      }
      : {
        send: 'ad_send_feed_cell',
        show: 'ad_show_feed_cell',
        click: 'ad_click_feed_cell',
      }
  const { ref, handleClick } = useAdStat(eventIds, info, customStat)
  return (
    <div
      className="box clearfix"
      onClick={() => {
        handleClick()
        window.open(href, '_blank')
      }}
      ref={ref}
    >
      <a target="_blank" className="photo fl">
        <Image src={cover} />
      </a>
      <div className="info">
        <a
          target="_blank"
          className="line-2 title"
          style={{ borderRadius: '2px' }}
        >
          {title}
        </a>
        <span className="slot" />
        <a target="_blank" className="name">
          <span className="ad-label">{label}</span>
          <span className="source">{source}</span>
          <span className="source">{formatTime(time * 1000)}</span>
        </a>
      </div>
      <style jsx>
        {`
          .box {
            height: 133px;
            border-bottom: 1px solid #ededed;
            padding: 16px 0;
            cursor: pointer;
          }
          .photo {
            position: relative;
            display: block;
            margin-right: 16px;
            width: 150px;
            height: 100px;
            overflow: hidden;
            cursor: pointer;
          }
          .photo.fl {
            border-radius: 2px;
          }
          .info {
            position: relative;
            display: table-cell;
            width: 100%;
            height: 100px;
            vertical-align: middle;
            text-align: left;
          }
          .slot {
            display: block;
            font-size: 14px;
            height: 22px;
            margin-top: 8px;
          }
          .title {
            line-height: 28px;
            font-size: 20px;
            font-weight: 500;
            cursor: pointer;
            transition: color 0.3s ease-in-out;
          }
          .title:hover {
            color: #e65800;
          }
          .title:visited {
            color: #999;
          }
          .source {
            color: #979aa8;
          }
          .name {
            display: flex;
            align-items: center;
            margin-top: 12px;
            margin-left: 6px;
            color: #969aa9;
          }
          .name span {
            margin-right: 12px;
          }
          .ad-label {
            height: 18px;
            padding: 0 3px;
            font-size: 10px;
            border: 1px solid #c9cbd6;
            border-radius: 2px;
            color: #979aa8;
            white-space: nowrap;
            display: inline-flex;
            align-items: center;
            justify-content: center;
          }
        `}
      </style>
    </div>
  )
}
