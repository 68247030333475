import React from 'react'
import Link from 'next/link'

import getLinkHref from 'lib/get-link-href'

interface IProps {
  /* icon图片路径 */
  icon?: string
  /* 左边的文本内容 */
  leftContent: string
  /* 右边的文本内容 */
  rightContent: string
  /* link前端路径 */
  href?: string
  /* link后端路径 */
  as?: string
  onClick?(): void
}

export default function Header({
  icon,
  leftContent,
  rightContent,
  href,
  as,
  onClick,
}: IProps) {
  // background: url(${require(`static/${icon}_ic@3x.png`)}) no-repeat
  //             center / 36px 36px;
  return (
    <header className="header">
      {leftContent === '热门排行榜' ? (
        <h2 className="title">{leftContent}</h2>
      ) : (
        <span className="title">{leftContent}</span>
      )}
      {href && as ? (
        <Link href={href} as={getLinkHref(as)}>
          <a target="_blank">
            <span
              className="more icon"
              onClick={() => {
                onClick && onClick()
              }}
            >
              {rightContent}
              <span className="icon-arrow-ic-r arrow-right" />
            </span>
          </a>
        </Link>
      ) : (
        <span className="more text">{rightContent}</span>
      )}
      <style jsx>
        {`
          .brands {
            width: 390px;
            min-height: 272px;
            font-size: 16px;
          }
          .header {
            height: 60px;
            line-height: 60px;
          }
          .title {
            display: inline;
            font-size: 24px;
            font-weight: 500;
            color: #1a1a1a;
          }
          .more {
            float: right;
          }
          .more:hover {
            cursor: pointer;
          }
          .text {
            font-size: 16px;
            color: #1a1a1a;
          }
          .arrow-right {
            display: inline-block;
            font-size: 16px;
            vertical-align: text-top;
            margin-top: 1.5px;
            margin-left: 4px;
          }
        `}
      </style>
    </header>
  )
}
