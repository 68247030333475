import React from 'react'

interface IFriendshipLink {
  friendshipLinkData: IFriendshipLinkData[]
}

export interface IFriendshipLinkData {
  name: string
  link: string
}

function FriendshipLink({ friendshipLinkData }: IFriendshipLink) {
  if (!friendshipLinkData || !friendshipLinkData.length) return null
  return (
    <div className="container">
      <div className="title">友情链接</div>
      <div className="content">
        {friendshipLinkData.map((friendshipLink: IFriendshipLinkData) => {
          return (
            <a
              className="link-item"
              href={friendshipLink.link}
              key={friendshipLink.name}
              rel="noopener noreferrer"
              target="_blank"
            >
              {friendshipLink.name}
            </a>
          )
        })}
      </div>
      <style jsx>
        {`
          .container {
            padding: 20px;
            padding-bottom: 4px;
            background-color: #f7f8fc;
            margin-top: 30px;
          }
          .container a:hover {
            color: #e65800;
          }
          .title {
            font-size: 18px;
            font-weight: 500;
            color: #1a1a1a;
            line-height: 1.33;
            margin-bottom: 20px;
          }
          .content {
            display: flex;
            flex-wrap: wrap;
          }
          .link-item {
            margin-right: 20px;
            color: #666666;
            margin-bottom: 16px;
          }
          .link-item:hover {
            color: rgb(61, 89, 129);
          }
        `}
      </style>
    </div>
  )
}

export default FriendshipLink
