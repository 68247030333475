import React from 'react'
export default function Label({
  text = '广告',
  className,
}: {
text: string
className?: string
}) {
  return (
    <span className={className}>
      {text}
      <style jsx>
        {`
          span {
            position: absolute;
            right: 8px;
            bottom: 8px;
            padding: 0 3px;
            height: 16px;
            line-height: 16px;
            text-align: center;
            font-size: 10px;
            color: rgba(255, 255, 255, 0.8);
            background: rgba(0, 0, 0, 0.3);
            border-radius: 2px;
            border-radius: 2px;
          }
        `}
      </style>
    </span>
  )
}
