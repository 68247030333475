/* 渲染feed流 */

import React, { useState, useRef, useEffect } from 'react'
import classnames from 'classnames'

import Feed from 'Components/feed'
import { useFeedAd } from 'Components/hooks'
import { sendTeaCommEvent } from 'lib/tea-analyze'

export interface TabData {
  text: string
  param: string
}

function HomeFeed({
  fromPage = 'home',
  tabData = [],
}: {
  fromPage?: string
  tabData?: TabData[]
}) {
  const container = useRef<HTMLDivElement>(null)
  /* 当前所在文章的栏目 */
  const [current, setCurrent] = useState('motor_car')
  const feedAd = useFeedAd(current, fromPage) || []

  useEffect(() => {
    if (tabData.length > 0) {
      setCurrent(tabData?.[0]?.param || 'motor_car')
    }
  }, [tabData])

  /* 渲染feed流都有哪些文章栏目 */
  function renderTitles() {
    let titles: { text: string; category: string }[]
    const navDomRef = useRef<HTMLUListElement>(null)

    useEffect(() => {
      if (navDomRef.current !== null && fromPage === 'feed') {
        const observer = new IntersectionObserver(
          ([e]) => {
            if (e.intersectionRatio < 1) {
              e.target.classList.add('stuck')
            } else if (e.intersectionRatio === 1) {
              e.target.classList.remove('stuck')
            }
          },
          { threshold: [1] }
        )
        observer.observe(navDomRef.current)
      }
    }, [navDomRef.current])
    if (fromPage === 'feed') {
      titles = tabData.map(e => ({ text: e.text, category: e.param }))
    } else {
      titles = [
        { text: '推荐', category: 'motor_car' },
        { text: '新车', category: 'motor_car_new_arrival' },
        { text: '用车', category: 'motor_car_use' },
        { text: '导购', category: 'motor_car_new_guide' },
        { text: 'SUV', category: 'motor_car_suv' },
        { text: '自驾游', category: 'motor_car_travel' },
        { text: '新能源', category: 'motor_car_new_energy' },
        { text: '提车作业', category: 'motor_car_trail_diary' },
        { text: '试驾评测', category: 'motor_car_evaluating' },
      ]
    }

    return (
      <ul
        ref={navDomRef}
        className={classnames({
          'from-feed-page': fromPage === 'feed',
          'use-default': fromPage !== 'feed',
        })}
      >
        {titles.map(
          (item: { text: string; category: string }, index: number) => (
            <li
              key={item.text}
              className={classnames({
                active: current === item.category,
              })}
              onClick={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
                e.stopPropagation()
                setCurrent(item.category)
                if (fromPage !== 'feed') {
                  sendTeaCommEvent('click_home_news_tab', {
                    sub_tab: item.text,
                  })
                } else {
                  sendTeaCommEvent('click_news_top_tab', {
                    rank: index,
                    obj_text: titles[index].text,
                  })
                }

                const element =
                  document.scrollingElement || document.documentElement
                if (element && container.current) {
                  const top = container.current.offsetTop
                  if (top <= element.scrollTop) {
                    element.scrollTop = container.current.offsetTop
                  }
                }
              }}
            >
              <span>{item.text}</span>
            </li>
          )
        )}
        <style jsx>
          {`
            ul.from-feed-page {
              background-color: white;
              z-index: 1;
              position: sticky;
              top: -1px;
              display: flex;
              flex-wrap: wrap;
              text-align: center;
              padding-bottom: 8px;
              padding-top: 9px;
              justify-content: flex-start;
              flex: 0 0 auto;
            }

            ul.stuck {
              padding-bottom: 0;
            }

            ul.stuck::after {
              position: absolute;
              bottom: -10px;
              height: 10px;
              width: 792px;
              content: '';
              background-image: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0.08),
                rgba(255, 255, 255, 0)
              );
            }

            .from-feed-page li {
              width: 72px;
              margin-right: 8px;
              margin-bottom: 8px;
              height: 32px;
              border-radius: 2px;
              background-color: #f2f4fa;
              cursor: pointer;
            }

            .from-feed-page li span {
              font-size: 14px;
              color: #1f2129;
              line-height: 32px;
            }

            .from-feed-page li.active {
              background-color: #1f2129;
            }
            .from-feed-page li.active span {
              color: white;
            }

            ul.use-default {
              box-shadow: 0 -1px #1f2129;
              position: sticky;
              top: 0;
              z-index: 10;
              width: 800px;
              height: 48px;
              line-height: 36px;
              font-size: 18px;
              color: #1f2129;
              background-color: #1f2129;
              border-radius: 2px;
              display: flex;
              text-align: center;
              margin-bottom: 16px;
              padding: 12px 40px;
              justify-content: space-between;
            }
            .use-default li {
              position: relative;
              line-height: 1.33;
              cursor: pointer;
              color: #ffffff;
            }
            .use-default li:first-child {
              padding-left: 0px;
            }
            .use-default li > span {
              display: inline-block;
              position: relative;
            }
            .use-default li:hover span,
            .active > span {
              font-weight: 500;
              color: #ffcd32;
            }
            .use-default li span::after {
              content: '';
              position: absolute;
              bottom: -12px;
              left: 0;
              right: 0;
              height: 4px;
              background: transparent;
            }

            .use-default .active > span::after {
              background: #ffcd32;
            }
          `}
        </style>
      </ul>
    )
  }

  return (
    <div ref={container} className="fl">
      {renderTitles()}
      <Feed
        page="feed"
        category={current}
        feedAd={feedAd}
        from="click_news_feed_item"
        onClick={(group_id, content_type, rank) => {
          if (fromPage === 'feed') {
            sendTeaCommEvent('click_news_feed_item', {
              rank,
              group_id,
              content_type,
            })
          }
        }}
      />
    </div>
  )
}
export default HomeFeed
