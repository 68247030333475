import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import ArticleLink from 'Components/links/article-link'
import 'lib/swiper.less'
import Header from 'Pages/home/common/header'
import getLinkHref from 'lib/get-link-href'
import { sendTeaCommEvent } from 'lib/tea-analyze'
import QRcode from './QRcode'
interface IProps {
  article: ArticleData[]
  fromPage?: string
}

export interface ArticleData {
  gid: number
  gid_str: string
  title: string
  count: number
  type: number
}

export default function ArticleBoard({
  article = [],
  fromPage = 'home',
}: IProps) {
  const [showModal, setShowModal] = useState(false)
  const [clientX, setClientX] = useState(0)
  const [clientY, setClientY] = useState(0)
  useEffect(() => {
    sendTeaCommEvent('web_client_show', {
      list_type: 1,
      item_type: 1,
      key_name: 'home_hot_article_card',
    })
  }, [])

  return (
    <>
      <section>
        <Header
          leftContent="文章排行榜"
          rightContent=""
          href=""
          as={getLinkHref('/newcar')}
          icon="type"
          onClick={() => {
            sendTeaCommEvent('click_new_series_rank_list_more')
          }}
        />
        <ul style={{ marginTop: '1px' }}>
          {Array.isArray(article) &&
            article.slice(0, 10).map((item, index) => {
              return (
                <li key={item.gid_str} className="line-1">
                  <ArticleLink
                    gid={item.gid_str}
                    onClick={() => {
                      if (fromPage === 'feed') {
                        sendTeaCommEvent('click_news_top_article', {
                          group_id: item?.gid_str || 'undefined',
                        })
                      }
                      sendTeaCommEvent('click_home_top_article', {
                        group_id: item.gid_str,
                      })
                    }}
                    className="line-1"
                  >
                    <span className={classnames(`gid-index gid-${index}`)}>
                      {index + 1}
                      {index < 3 ? null : '.'}
                    </span>
                    <span
                      className={classnames('gid-title', {
                        'gid-title-bold': index < 3,
                      })}
                    >
                      {item.title}
                    </span>
                  </ArticleLink>
                  <span className="gid-count">{item.count}万次阅读</span>
                </li>
              )
            })}
        </ul>

        <QRcode
          clientX={clientX}
          clientY={clientY}
          show={showModal}
          onHide={() => {
            setShowModal(!showModal)
          }}
        />
      </section>
      <style jsx>
        {`
          mark {
            font-family: PingFangSC;
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.4;
            letter-spacing: normal;
            text-align: center;
            color: #e65800;
            margin: 2px 4px 2px 0px;
            padding: 1px 4px;
            border-radius: 2px;
            background-color: rgba(230, 88, 0, 0.1);
          }
          .featured-videos-wrapper {
            display: flex;
          }

          .car-gid {
            width: 380px;
            height: 260px;
            border-radius: 2px;
          }
          .line-1 {
            margin-bottom: 17px;
            display: flex;
            width: 100%;
          }
          .gid-index {
            display: inline-block;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 18px;
            letter-spacing: normal;
            color: #979aa8;
            width: 18px;
            height: 18px;
            border-radius: 2px;
            text-align: center;
            margin-right: 8px;
          }
          .gid-0 {
            background-color: #e62021;
            color: #ffffff;
          }
          .gid-1 {
            background-color: #e65800;
            color: #ffffff;
          }
          .gid-2 {
            background-color: #f5b330;
            color: #ffffff;
          }
          .gid-title {
            font-size: 14px;
          }
          .gid-title-bold {
            font-weight: 500;
          }
          .gid-count {
            margin-left: auto;
            font-size: 12px;
            color: #979aa8;
            padding-left: 12px;
          }
        `}
      </style>
    </>
  )
}
