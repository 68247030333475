import React, { PureComponent } from 'react'
import classNames from 'classnames'
import { Fade } from './animation'
import Portal from './portal'

export interface IModalProps {
  /** 样式类名 */
  className?: string
  title?: React.ReactNode
  children: React.ReactNode
  maskClosable: boolean
  /** 关闭后回调 */
  onClose?: (e: React.MouseEvent<any>) => void
  /** 是否可见 */
  visible: boolean
  /** 获取挂载容器 */
  getContainer?: () => HTMLElement
  /** 底部配置 */
  footer?: React.ReactNode
  /** 是否展示关闭按钮 */
  closable: boolean
  /** 关闭函数 */
  close: () => void
  /** 内容宽度 */
  width?: number | string
  /** 内容高度 */
  height?: number | string
  clientX: number
  clientY: number
  type?: number
  price?: string
}

export default class Modal extends PureComponent<IModalProps> {
  static defaultProps: Partial<IModalProps> = {
    closable: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    close: () => {},
    maskClosable: true,
    visible: false,
  }

  getContainer = () => {
    const { getContainer } = this.props
    if (getContainer) {
      return getContainer()
    }
    const container = document.createElement('div')
    document.body.appendChild(container)
    return container
  }

  handleMaskClick = (e: React.MouseEvent<HTMLElement>) => {
    const { maskClosable } = this.props
    if (maskClosable && e.target === e.currentTarget) {
      this.onClose(e)
    }
  }

  onClose = (e: React.MouseEvent<HTMLElement>) => {
    const { onClose, close } = this.props
    onClose && onClose(e)
    close()
  }

  onEntered = () => {
    // const clientWidthBefore = document.body.clientWidth
    // document.body.style.overflow = 'hidden'
    // const clientWidthAfter = document.body.clientWidth
    // const scrollBarWidth = clientWidthAfter - clientWidthBefore
    // document.body.style.paddingRight = `${scrollBarWidth}px`
  }

  onExited = () => {
    // document.body.style.overflow = ''
    // document.body.style.paddingRight = '0'
  }

  getComponent() {
    const {
      visible,
      className,
      children,
      title,
      closable,
      footer,
      width,
      height,
      clientX,
      clientY,
      onClose,
      price,
      type,
    } = this.props

    console.log(this.props)
    const dest: any = {}
    if (width !== undefined) {
      dest.width = width
    }
    if (height !== undefined) {
      dest.height = height
    }

    return (
      <Fade
        timeout={100}
        in={visible}
        onEntered={this.onEntered}
        onExited={this.onExited}
        unmountOnExit
      >
        <div
          className={classNames('modal-mask', className)}
          onClick={this.handleMaskClick}
        >
          <div className="modal-container" style={dest}>
            <div
              onClick={e => {
                onClose && onClose(e)
              }}
            >
              <a target="_blank">
                <img
                  className="close-btn"
                  src="https://sf3-ttcdn-tos.pstatp.com/obj/motor-img/395544e4303e9d3b9954e7234a34fec8"
                />
              </a>
            </div>
            {!!title && (
              <div className="modal-header">
                <div className="modal-title">{title}</div>
                {type === 1 ? (
                  <div className="modal-sub-price">1元</div>
                ) : (
                  <div className="modal-sub-price">{price}</div>
                )}
                {closable && (
                  <i
                    className="modal-close icon-dislike-ic"
                    onClick={this.onClose}
                  />
                )}
              </div>
            )}
            <div className="modal-body">{children}</div>
            {!!footer && <div className="modal-footer">{footer}</div>}
          </div>
          <style jsx>
            {`
              .modal-mask {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1040;
                width: 100vw;
                height: 100vh;
                background-color: #000;
              }

              .fade {
                opacity: 1;
                transition: all 100ms ease-in;
                background-color: rgba(0, 0, 0, 0);
              }

              .modal-mask.show {
                background-color: rgba(0, 0, 0, 0);
              }

              .close-btn {
                position: absolute;
                z-index: 1041;
                top: 8px;
                right: 8px;
                width: 16px;
                height: 16px;
              }
              .modal-container {
                position: fixed;
                top: ${clientY}px;
                left: ${clientX - 220}px;
                width: 800px;
                overflow: hidden;
                background-color: #fff;
                border-radius: 4px;
                box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.18);
              }

              .modal-header {
                padding-top: 24px;
                position: relative;
              }
              .modal-title {
                color: #1f2129;
                text-align: center;
                font-size: 18px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
              }

              .modal-sub-title {
                font-size: 12px;
                text-align: center;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 2;
                letter-spacing: normal;
                margin-bottom: 12px;
              }

              .modal-sub-price {
                margin-top: 12px;
                font-size: 18px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                text-align: center;
                color: #e62021;
                margin-bottom: 4px;
              }

              .modal-body {
                padding-bottom: 32px;
                overflow-y: auto;
                max-height: calc(100% - 3.5rem);
              }

              .modal-footer {
                padding: 20px;
                text-align: center;
              }

              .modal-close {
                font-size: 16px;
                position: absolute;
                right: 20px;
                top: 20px;
                width: 16px;
                height: 16px;
                cursor: pointer;
              }
            `}
          </style>
        </div>
      </Fade>
    )
  }

  render() {
    return (
      <Portal getContainer={this.getContainer}>{this.getComponent()}</Portal>
    )
  }
}
