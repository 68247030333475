import * as React from 'react'
import Link from 'next/link'

interface IProps {
  gid: string
  className?: string
  isVideo?: boolean
  children: React.ReactNode
  onClick?(gid: string): void
}

export default function ArticleLink(props: IProps) {
  const { gid, children, className, onClick } = props
  // if (isVideo) {
  //   return (
  //     <a className={className} href={`//auto.365yg.com/motor/article/${gid}`}>
  //       {children}
  //     </a>
  //   )
  // }
  return (
    <>
      <Link href={`/article?gid=${gid}`} as={`/article/${gid}`}>
        <a
          className={className}
          onClick={() => {
            onClick && onClick(gid)
          }}
          target="_blank"
        >
          {children}
        </a>
      </Link>
      <style jsx>
        {`
          .article-link {
            line-height: 24px;
          }
          .today-link {
            min-width: 0;
            /*max-width: calc(100% / 3);*/
          }
        `}
      </style>
    </>
  )
}
