export default function getFakeWebid(): string {
  let id = ''
  try {
    const storage = sessionStorage.getItem('fakeWebid')
    if (storage) {
      id = storage
    }
  } catch (err) {}
  if (!id) {
    id = getInt64()
  }
  try {
    sessionStorage.setItem('fakeWebid', id)
  } catch (err) {}
  return id
}

function getInt64(): string {
  const min = 0
  const max = Number.MAX_SAFE_INTEGER || 9007199254740991
  return `${Math.floor(Math.random() * (max - min + 1) + min)}`
}
