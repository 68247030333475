// feed流接口

import fetch from 'isomorphic-unfetch'
import serializeObject from '@arsenal/arsenal/modules/serializeObject'
import getUrl from './url'
import getFakeWebid from 'lib/fake-webid'

interface IParams {
  // 下拉刷新时需要改变
  tt_from: 'enter_auto' | 'load_more' | ''
  // 频道
  category: string
  // 子频道
  sub_category?: string
  /* 车系id */
  concern_id?: number
  // 数量
  count?: number
  // 加载更多时最后一条数据的behot_time.
  max_behot_time?: number
  min_behot_time?: number
}

/**
 * feed流单个数据
 */
export interface IFeedData {
  type: number
  unique_id_str: string
  info: {
    abstract_content: string
    source: string
    title: string
    group_id: string
    video_id?: string
    video_duration: number
    image_list: [
      {
        url: string
        width: number
        height: number
        url_list: { url: string }[]
      }
    ]
    read_count: number
    comment_count: number
    article_type: number
    behot_time: number
    display_time: number
    user_info: {
      user_id: number
      name: string
      verified_content: string
      avatar_url: string
      user_verified: boolean
    }
    cursor: number
    share_url: string
    video_detail_info: {
      detail_video_large_image: {
        url: string
      }
    }
  }
}

/**
 * feed流返回数据
 */
export interface IFeedResult {
  tips: {
    display_info: string
  }
  data: IFeedData[]
}

export default (params: IParams): Promise<IFeedResult> => {
  const { category } = params
  let extraParams = {}
  if (category === 'motor_local') {
    const cityName = window.localStorage.getItem('cityName') || ''
    extraParams = {
      city: cityName,
      city_name: cityName,
      selected_city_name: cityName,
    }
  }

  const defaultParams = {
    impression_info: JSON.stringify({
      page_id: 'page_car_series',
      sub_tab: params.category,
      product_name: 'web',
      car_series_id: params.concern_id,
    }),
    aid: '1839',
    refer: 1,
    channel: 'web',
    device_platform: 'web',
    // iid: Cookies('tt_webid', {}),
    web_id: getFakeWebid(),
    motor_feed_extra_params: '{"new_feed": true, "feed_type": 1}',
  }
  // console.log(params)
  const url = getUrl(
    `/motor/stream/api/news/feed/web/v47/?${serializeObject(
      params
    )}&${serializeObject({ ...defaultParams, ...extraParams })}`
  )
  return fetch(url)
    .then(res => res.json())
    .then((result: Partial<IFeedResult> | null) => {
      if (!result || !result.data) {
        throw new Error(`Get Feed Error ${JSON.stringify(result)}`)
      }
      // if (params.category === 'video') {
      //   result.data = result.data.filter(item => item.info.video_id)
      // }
      return result as IFeedResult
    })
}
