import React, { memo } from 'react'
import Modal from 'Components/toastModal'

export interface IDeclareTextProps {
  show: boolean
  onHide: () => void
  clientX: number
  clientY: number
  type?: number
  price?: string
}
function DeclareText({
  show,
  onHide,
  clientX,
  clientY,
  type = 1,
  price,
}: IDeclareTextProps) {
  return (
    <>
      <Modal
        type={type}
        width={220}
        visible={show}
        onClose={onHide}
        title={type === 1 ? '欢迎付费阅读' : '欢迎付费购买'}
        clientX={clientX}
        clientY={clientY}
        price={price}
      >
        <div className="agree-content">
          <div className="img-wrapper">
            <img
              className="qr_code"
              src="https://sf1-ttcdn-tos.pstatp.com/img/motor-img/a49b1402cad5fa86c751081847352d98~noop.jpg"
              alt="QRcode"
            />
          </div>
        </div>
      </Modal>
      <style jsx>
        {`
          .agree-content {
            max-height: 50vh;
          }

          .btnok {
            font-size: 18px;
            width: 320px;
            height: 40px;
            border-radius: 4px;
            background-color: #ffe100;
            text-align: center;
            line-height: 40px;
            border: none;
            outline: none;
            cursor: pointer;
          }

          .agree-content {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .img-wrapper {
            padding: 8px;
            background-color: #f7f8fc;
          }
          .qr_code {
            display: block;
            width: 108px;
            height: 108px;
          }
        `}
      </style>
    </>
  )
}

export default memo(DeclareText)
